import { useState, useEffect } from "react";
import { createContainer } from "unstated-next";

export interface IListItem {
  id: string;
  text: string;
  important: boolean;
  finished: boolean;
  timeEntries?: number[];
  lastStart?: Date;

  updated?: number;
}

function useOnStorage(callback: (event: StorageEvent) => void) {
  useEffect(() => {
    window.addEventListener("storage", callback);

    return () => window.removeEventListener("storage", callback);
  }, [callback]);
}

function useTasks(initialState: IListItem[] = []) {
  const [items, setItems] = useState<IListItem[]>(initialState);
  useOnStorage((event) => {
    const storedTasksString = window.localStorage.getItem("tasks");

    if (storedTasksString) {
      // TODO: rewrite using `update` function and implement handling of the deleted tasks
      const storedTasks = JSON.parse(storedTasksString);
      setItems(storedTasks);
    }
  });
  useEffect(() => {
    window.localStorage.setItem("tasks", JSON.stringify(items));
  }, [items]);

  return {
    items,
    get(id: string): IListItem | undefined {
      return items.find((item) => item.id === id);
    },
    add(item: IListItem) {
      setItems((currentItems) => [
        ...currentItems,
        {
          ...item,
          updated: Date.now(),
        },
      ]);
    },
    update(id: string, values: Partial<IListItem>) {
      setItems((currentItems) =>
        currentItems.map((item) => {
          if (item.id === id) {
            if (
              values.updated &&
              item.updated &&
              values.updated < item.updated
            ) {
              console.warn(
                `Cannot update ${item.id} with older values (${values.updated} < ${item.updated})`
              );
              return item;
            }

            console.log("updating", id, values);

            return {
              ...item,
              ...values,
              updated: Date.now(),
            };
          }

          return item;
        })
      );
    },
    remove(id: string) {
      setItems((currentItems) => currentItems.filter((item) => item.id !== id));
    },
  };
}

const Tasks = createContainer(useTasks);

export default Tasks;
