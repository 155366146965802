import React, { useState } from "react";
import "./List.css";
import hyperid from "hyperid";
import * as moment from "moment";

import TasksContainer from "../containers/tasks";
import { useHistory } from "react-router-dom";

const getId = hyperid({
  urlSafe: true,
});

function isImportant(text: string) {
  if (text.toUpperCase() === text) {
    return true;
  }

  return /^!!!.*/.test(text);
}

export interface IListProps {
  title: string;
}

const NEW_ITEM_PLACEHOLDER = "+ new item";

function sumarizeEntries(timeEntries: number[] = []) {
  return timeEntries.reduce((total, entry) => total + entry, 0);
}

const List: React.SFC<IListProps> = ({ title }) => {
  const [placeholder, setPlaceholder] = useState(NEW_ITEM_PLACEHOLDER);
  const { add, remove, update, items } = TasksContainer.useContainer();
  const [newItemValue, setNewItemValue] = useState("");
  const history = useHistory();

  const sortedItems = items.sort((a, b) => {
    if (a.finished && b.finished) return 0;
    if (a.finished && !b.finished) return 1;
    if (!a.finished && b.finished) return -1;

    return 0;
  });

  const entriesDurations = items.map((item) =>
    sumarizeEntries(item.timeEntries)
  );
  const totalDuration = sumarizeEntries(entriesDurations);

  return (
    <div className="list-container">
      <h1>
        {title}{" "}
        {!!totalDuration && (
          <span className="small">
            {`(${moment.duration(totalDuration, "millisecond").humanize()})`}
          </span>
        )}
      </h1>
      <ul className="list">
        <li className="list-item">
          <input
            type="text"
            placeholder={placeholder}
            value={newItemValue}
            onBlur={() => setPlaceholder(NEW_ITEM_PLACEHOLDER)}
            onFocus={() => setPlaceholder("")}
            onChange={(e) => {
              setNewItemValue(e.target.value);
            }}
            onKeyPress={(e) => {
              if (
                (e.keyCode === 13 || e.which === 13) &&
                newItemValue.trim() !== ""
              ) {
                e.preventDefault();
                add({
                  id: getId(),
                  important: isImportant(newItemValue),
                  text: newItemValue,
                  finished: false,
                });
                setNewItemValue("");
              }
            }}
            className="new-input"
          />
        </li>

        {sortedItems.map((item, i) => (
          <li className="list-item" key={item.id}>
            <div className="controls">
              <div
                className="control"
                onClick={() => {
                  if (item.finished) {
                    remove(item.id);
                  } else {
                    update(item.id, {
                      finished: true,
                    });
                  }
                }}
              >
                &times;
              </div>
            </div>

            <span
              className={`content
              ${item.important ? "important" : ""}
              ${item.lastStart ? "running" : ""}
              ${item.finished ? "finished" : ""}`}
              onClick={() => {
                if (!item.lastStart) {
                  console.log("updating last start");
                  update(item.id, {
                    lastStart: new Date(),
                  });
                }
                // update(item.id, {
                //   finished: false,
                // });
                history.push(`/task/${item.id}`);
              }}
            >
              {item.text}{" "}
            </span>
            {item.timeEntries && (
              <span
                style={{
                  marginLeft: "11px",
                }}
                className="small"
              >
                {" "}
                {`(${moment
                  .duration(sumarizeEntries(item.timeEntries), "millisecond")
                  .humanize()})`}
              </span>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default List;
