import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ListScreen from "./screens/ListScreen";
import TaskScreen from "./screens/TaskScreen";
import TasksContainer from "./containers/tasks";

function App() {
  let initialState = [];

  const storedTasks = window.localStorage.getItem("tasks");
  if (storedTasks) {
    initialState = JSON.parse(storedTasks);
  }

  return (
    <TasksContainer.Provider initialState={initialState}>
      <div className="App">
        <Router>
          <Switch>
            <Route path="/task/:taskId">
              <TaskScreen />
            </Route>
            <Route path="/">
              <ListScreen />
            </Route>
          </Switch>
        </Router>
      </div>
    </TasksContainer.Provider>
  );
}

export default App;
