import React, { useRef, useEffect, useState } from "react";
import "./Task.css";
import TasksContainer from "../containers/tasks";
import { useHistory } from "react-router-dom";
import * as moment from "moment";

export interface ITaskProps {
  id: string;
}

function useInterval(callback: (delta: number) => void, delay: number = 1000) {
  const savedCallback = useRef<(delta: number) => void>();
  const lastTimestamp = useRef<number>(Date.now());

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      const now = Date.now();

      const delta = now - lastTimestamp.current;
      savedCallback.current?.(delta);

      lastTimestamp.current = now;
    }

    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const Task: React.SFC<ITaskProps> = ({ id }) => {
  const { get, update } = TasksContainer.useContainer();

  const initialTime = get(id)?.lastStart?.getTime?.();
  const [elapsedTime, setElapsedTime] = useState(
    initialTime ? Date.now() - initialTime : 0
  );

  const history = useHistory();
  useInterval((delta) => {
    setElapsedTime(elapsedTime + delta);
  });

  const item = get(id);

  const handleDoneClick = (finished: boolean) => {
    let duration = 0;

    if (item?.lastStart) {
      duration = Date.now() - item.lastStart.getTime();
    }

    console.log(item, item?.lastStart);

    update(id, {
      finished,
      lastStart: undefined,
      timeEntries: [...(item?.timeEntries ?? []), duration],
    });
    history.replace("/");
  };

  return (
    <div className="task-container">
      <h1>Task</h1>

      <div className="single-item">
        <div className="content">{item?.text}</div>
        <div className="controls">
          <div
            className="button gray"
            onClick={() => {
              handleDoneClick(false);
            }}
          >
            Pause
          </div>

          <div
            className="button blue"
            onClick={() => {
              handleDoneClick(true);
            }}
          >
            Done
          </div>
        </div>
      </div>
      <div className="duration">
        {moment.duration(elapsedTime, "millisecond").humanize()}
      </div>
    </div>
  );
};

export default Task;
