import React, { SFC } from "react";
import { useParams } from "react-router-dom";
import Task from "../components/Task";

const TaskScreen: SFC = () => {
  const { taskId } = useParams();

  if (!taskId) return null;
  return <Task id={taskId} />;
};

export default TaskScreen;
